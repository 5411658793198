<template>
  <div class="tab-content">
    
    <div class="form-container--small">
      <label>Pilih Jenjang</label><br />
      <b-form-select
        v-model="formData.level_id"
        :options="levelOption"
        required
        class="mrr-22 mrb-5 my-select"
        @change="changeLevel"
      ></b-form-select>

      <label class="mt-4">Pilih Kelas</label><br />
      <b-form-select
        v-model="formData.class_id"
        :options="classOption"
        required
        class="mrr-22 mrb-5 my-select"
        @change="changeClass"
      ></b-form-select>

      <label class="mt-4">Pilih Mata Pelajaran</label><br />
      <b-form-select
        v-model="formData.subject_id"
        :options="subjectOption"
        required
        class="mrr-22 mrb-5 my-select"
        @change="changeSubject"
      ></b-form-select>

      <label class="mt-4">Pilih Topik atau Bab</label><br />
      <b-form-select
        v-model="formData.chapter_id"
        :options="chapterOption"
        required
        class="mrr-22 mrb-5 my-select"
      ></b-form-select>

      <label class="mt-4">Nama Playlist</label><br />
      <b-form-input
        placeholder="Contoh: Pengertian Vektor"
        v-model="formData.name"
      ></b-form-input>

      <div class="upload-image mt-4">
        <div class="upload-image__item">
          <div class="upload-image__top">
            <label>Thumbnail</label> <br />
            <div class="cat-icon-container">
              <img
                class="image-container-img"
                :src="formData.thumbnail_src"
                alt="img"
                style="max-height:108px"
              />
              <div
                class="img-remove"
                v-show="formData.thumbnail_src != '/img/category-icons/thumbnail-default.svg'"
                @click="removeImage()"
              >
                X Remove
              </div>
            </div>
          </div>
          <div class="upload-image__bottom">
            <input
              type="file"
              :name="formData.thumbnail"
              id="inputIcon"
              class="hidden-input"
              @change="changeThumbnail()"
            />
            <button for="inputIcon" class="set-icon">
              <fa-icon icon="upload" class="my-icon" />
              UNGGAH
            </button>
          </div>
        </div>
        <div class="upload-image__item mrl-50">
          <div class="upload-image__top"></div>

          <div class="upload-image__bottom">
            <b-form-checkbox
              name="check-button"
              @change="handleSwitchDataChange"
            >
              Set Free
            </b-form-checkbox>
          </div>
        </div>
      </div>

      <label class="mt-4">Deskripsi</label><br />
      <b-textarea
        placeholder="Tambahkan deskripsi..."
        rows="4"
        v-model="formData.description"
      ></b-textarea
      ><br /><br />
    </div>

    <div class="form-container">
      <my-button
          type="blue"
          disabled
          size="lg" 
          v-if="showLoading"
          rounded block
        >
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </my-button>
      <my-button type="blue" size="lg" rounded block v-else @click="save">
        SIMPAN
      </my-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      showLoading: false,
      levelOption: [],
      classOption: [{value: null, text: "Tidak Ada Kelas"}],
      subjectOption: [{value: null, text: "Tidak Ada Mata Pelajaran"}],
      chapterOption: [{value: null, text: "Tidak Ada Materi"}],
      formData: {
        level_id: null,
        class_id: null,
        subject_id: null,
        chapter_id: null,
        name: "",
        description: "",
        thumbnail: null,
        thumbnail_src: "/img/category-icons/thumbnail-default.svg",
        pay: 1,
      },
    };
  },

  mounted() {
    this.levelList();
  },

  methods: {
    ...mapActions({
      getLevelList: types.GET_LEVEL_LIST,
      getSubjectList: types.GET_SUBJECT_LIST,
      getChapterList: types.GET_CHAPTER_LIST,
      getClassList: types.GET_CLASS_LIST,
      setNewSection: types.SET_NEW_SECTION
    }),
    handleSwitchDataChange(val) {
      if(val) {
        this.formData.pay = 0;
      } else {
        this.formData.pay = 1;
      }
    },
    levelList() {
      this.getLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [{value: null, text: "Pilih"}];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    classList() {
      if(this.formData.level_id) {
        this.getClassList({params: {level: this.formData.level_id}})
          .then(response => {
            var res = response;
            if(response.length > 0) {
              this.classOption = [{value: null, text: "Pilih"}];
            } else {
              this.classOption = [{value: null, text: "Tidak Ada Kelas"}];
            }
            for (var i = 0; i < res.length; i++) {
              var dataclass = {
                value: res[i].id,
                text: res[i].major ? res[i].name+' '+res[i].major:res[i].name
              };
              this.classOption.push(dataclass);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.classOption = [{value: null, text: "Tidak Ada Kelas"}];
      }
    },
    subjectList() {
      if(this.formData.level_id && this.formData.class_id) {
        this.getSubjectList({params: {level: this.formData.level_id, class: this.formData.class_id}})
          .then(response => {
            var res = response;
            if(response.length > 0) {
              this.subjectOption = [{value: null, text: "Pilih"}];
            } else {
              this.subjectOption = [{value: null, text: "Tidak Ada Mata Pelajaran"}];
            }
            for (var i = 0; i < res.length; i++) {
              var datasubject = {
                value: res[i].id,
                text: res[i].name
              };
              this.subjectOption.push(datasubject);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.subjectOption = [{value: null, text: "Tidak Ada Mata Pelajaran"}];
      }
    },
    chapterList() {
      if(this.formData.level_id && this.formData.class_id && this.formData.subject_id) {
        this.getChapterList({params: {level: this.formData.level_id, subject: this.formData.subject_id, class: this.formData.class_id}})
          .then(response => {
            var res = response;
            if(response.length > 0) {
              this.chapterOption = [{value: null, text: "Pilih"}];
            } else {
              this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
            }
            for (var i = 0; i < res.length; i++) {
              var datasubject = {
                value: res[i].id,
                text: res[i].name+' '+res[i].curriculum
              };
              this.chapterOption.push(datasubject);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
      }
    },
    changeLevel() {
      this.classList();
      this.formData.class_id = null;
      this.formData.subject_id = null;
      this.formData.chapter_id = null;
      this.subjectOption = [{value: null, text: "Tidak Ada Mata Pelajaran"}];
      this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
    },
    changeClass() {
      this.subjectList();
      this.formData.subject_id = null;
      this.formData.chapter_id = null;
      this.chapterOption = [{value: null, text: "Tidak Ada Materi"}];
    },
    changeSubject() {
      this.formData.chapter_id = null;
      this.chapterList();
    },
    save() {
      if(this.formData.level_id && this.formData.class_id && this.formData.subject_id && this.formData.chapter_id && this.formData.name) {
        this.showLoading = true;
        let newFormData = new FormData();
        newFormData.append("name", this.formData.name);
        newFormData.append("description", this.formData.description);
        newFormData.append("chapter_id", this.formData.chapter_id);
        newFormData.append("thumbnail", this.formData.thumbnail);
        newFormData.append("pay", this.formData.pay);
        this.setNewSection(newFormData)
          .then(response => {
            this.$router.push("/dashboard/module/edit/"+response.id);
          })
          .catch(error => {
            this.showLoading = false;
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.showMessageBox("Lengkapi data", "Failed", "success");
      }
    },
    changeThumbnail() {
      var fileData = event.target.files[0];
      this.formData.thumbnail = fileData;
      this.formData.thumbnail_src = URL.createObjectURL(fileData);
    },
    removeImage() {
      this.formData.thumbnail_src = "/img/category-icons/thumbnail-default.svg";
      this.formData.thumbnail = null;
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  },
};
</script>

<style>
.cat-icon-container {
  width: 108px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f8ff;
}
</style>
